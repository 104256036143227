<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12 md6>
        <h1>Claves AES</h1>
      </v-flex>
    </v-layout>

    <v-card elevation="1" class="mt-3">
      <v-simple-table dense>
        <template v-slot:top>
          <v-toolbar flat>
            <div class="flex-grow-1"></div>
            <v-btn
              class="mr-2"
              color="primary"
              elevation="2"
              x-small
              @click="keysVisible = !keysVisible"
            >
              <v-icon small>mdi-eye</v-icon>Ver claves
            </v-btn>
          </v-toolbar>
        </template>
        <template>
          <thead>
            <tr>
              <th>Número de Clave</th>
              <th>Clave</th>
              <th>Fecha creación</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in operator.aesHistory" :key="item.secKey">
              <td>{{ item.secKey.toString().padStart(2, "0") }}</td>
              <td>
                {{ keysVisible ? decodeB64(item.aesParaph) : "****************" }}
              </td>
              <td>{{ item.creationDate }}</td>
              <td class="text-right">
                <v-btn
                  outlined
                  color="primary"
                  elevation="2"
                  x-small
                  @click="copy(item)"
                  title="Copiar al portapapeles"
                >
                  <v-icon small>mdi-content-copy</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Integrators",
  data: () => ({
    keysVisible: false,
  }),
  created() {
    this.$store.dispatch("fetchOperator");
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    operator() {
      return this.$store.getters.getOperator;
    },
  },
  methods: {
    decodeB64: function(value) {
      return atob(value.replace("<", "").replace(">", ""));
    },
    copy: function(item) {
      var aux = document.createElement("input");
      aux.setAttribute("value", this.decodeB64(item.aesParaph));
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.$store.commit("showBanner", {
        bannerMessage: "Clave AES copiada al portapapeles.",
        bannerType: "success",
      });
    },
  },
};
</script>

<style scoped></style>
